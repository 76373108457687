var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('title',[_vm._v("Galactiq | Discord")]),_vm._m(0),_c('section',{staticClass:"content-row content-row-gray content-row-color"},[_c('div',{staticClass:"container"},[_c('header',{staticClass:"content-header"},[_c('h2',[_vm._v(" Discord Bot Hosting ")]),_c('div',{staticClass:"tab-group tab-group-switch-style"},[_c('ul',[_c('li',{class:_vm.$root.$children[0].period == 1 ? `active` : ``,on:{"click":function($event){return _vm.$root.$children[0].setPeriod(1)}}},[_vm._v(" 1 month ")]),_c('li',{class:_vm.$root.$children[0].period == 3 ? `active` : ``,on:{"click":function($event){return _vm.$root.$children[0].setPeriod(3)}}},[_vm._v(" 3 months ")]),_c('li',{class:_vm.$root.$children[0].period == 6 ? `active` : ``,on:{"click":function($event){return _vm.$root.$children[0].setPeriod(6)}}},[_vm._v(" 6 months ")]),_c('li',{class:_vm.$root.$children[0].period == 12 ? `active` : ``,on:{"click":function($event){return _vm.$root.$children[0].setPeriod(12)}}},[_vm._v(" 12 months ")])])])]),_c('div',{staticClass:"column-row align-center-bottom"},[_c('div',{staticClass:"column-33"},[_c('div',{staticClass:"product-box"},[_vm._m(1),_c('div',{staticClass:"product-price"},[_vm._v(" $"+_vm._s(_vm.$root.$children[0].floatPriceToString(0.75))),_c('span',{staticClass:"term"},[_vm._v("/ month")])]),_vm._m(2),_vm._m(3)])]),_c('div',{staticClass:"column-33"},[_c('div',{staticClass:"product-box"},[_vm._m(4),_c('div',{staticClass:"product-price"},[_vm._v(" $"+_vm._s(_vm.$root.$children[0].floatPriceToString(1.25))),_c('span',{staticClass:"term"},[_vm._v("/ month")])]),_vm._m(5),_vm._m(6)])]),_c('div',{staticClass:"column-33"},[_c('div',{staticClass:"product-box"},[_vm._m(7),_c('div',{staticClass:"product-price"},[_vm._v(" $"+_vm._s(_vm.$root.$children[0].floatPriceToString(3.35))),_c('span',{staticClass:"term"},[_vm._v("/ month")])]),_vm._m(8),_vm._m(9)])])]),_vm._m(10),_vm._m(11)])]),_vm._m(12)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"content-row content-row-color content-gamebanner"},[_c('div',{staticClass:"container"},[_c('header',{staticClass:"content-header content-header-large content-header-uppercase"},[_c('h1',[_c('mark',[_vm._v("Discord")])]),_c('p',[_vm._v(" Host your discord bot on powerful hardware to make sure it always gets the job done ")]),_c('p')])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"product-header"},[_c('img',{attrs:{"src":"https://cdn.galactiq.net/images/game-icons/bot-logo3.png","alt":"","height":"64px"}}),_c('h4',{staticStyle:{"margin-top":"20px"}},[_vm._v(" Nebula ")]),_c('p',[_vm._v("DCBOT1"),_c('br')]),_c('small',[_c('a',{attrs:{"href":"/network"}},[_vm._v("View Locations")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"product-features"},[_c('ul',[_c('li',[_c('strong',[_vm._v("0.5")]),_vm._v(" CPU cores")]),_c('li',[_c('strong',[_vm._v("128 MB")]),_vm._v(" RAM")]),_c('li',[_c('strong',[_vm._v("1 GB")]),_vm._v(" Disk space")])]),_c('ul')])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"product-order"},[_c('a',{staticClass:"button button-secondary",attrs:{"href":"https://console.galactiq.net","target":"_blank"}},[_c('i',{staticClass:"fas fa-shopping-cart icon-left"}),_vm._v("Order Now ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"product-header"},[_c('img',{attrs:{"src":"https://cdn.galactiq.net/images/game-icons/bot-logo3.png","alt":"","height":"64px"}}),_c('h4',{staticStyle:{"margin-top":"20px"}},[_vm._v(" Galaxy ")]),_c('p',[_vm._v("DCBOT2"),_c('br')]),_c('small',[_c('a',{attrs:{"href":"/network"}},[_vm._v("View Locations")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"product-features"},[_c('ul',[_c('li',[_c('strong',[_vm._v("1")]),_vm._v(" CPU core")]),_c('li',[_c('strong',[_vm._v("1 GB")]),_vm._v(" RAM")]),_c('li',[_c('strong',[_vm._v("4 GB")]),_vm._v(" Disk space")])]),_c('ul')])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"product-order"},[_c('a',{staticClass:"button button-secondary",attrs:{"href":"https://console.galactiq.net","target":"_blank"}},[_c('i',{staticClass:"fas fa-shopping-cart icon-left"}),_vm._v("Order Now ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"product-header"},[_c('img',{attrs:{"src":"https://cdn.galactiq.net/images/game-icons/bot-logo3.png","alt":"","height":"64px"}}),_c('h4',{staticStyle:{"margin-top":"20px"}},[_vm._v(" Cosmos ")]),_c('p',[_vm._v("DCBOT3"),_c('br')]),_c('small',[_c('a',{attrs:{"href":"/network"}},[_vm._v("View Locations")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"product-features"},[_c('ul',[_c('li',[_c('strong',[_vm._v("1")]),_vm._v(" CPU core")]),_c('li',[_c('strong',[_vm._v("3 GB")]),_vm._v(" RAM")]),_c('li',[_c('strong',[_vm._v("15 GB")]),_vm._v(" Disk space")])]),_c('ul')])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"product-order"},[_c('a',{staticClass:"button button-secondary",attrs:{"href":"https://console.galactiq.net","target":"_blank"}},[_c('i',{staticClass:"fas fa-shopping-cart icon-left"}),_vm._v("Order Now ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('header',{staticClass:"content-header"},[_c('ul'),_c('h1',[_c('mark',[_vm._v("Server Platforms")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"column-row align-center-bottom"},[_c('div',{staticClass:"column-33"},[_c('div',{staticClass:"product-box"},[_c('div',{staticClass:"product-header"},[_c('img',{staticStyle:{"margin-top":"calc((64px - 23.73px) / 2)","margin-bottom":"calc((64px - 23.73px) / 2)"},attrs:{"src":"https://cdn.galactiq.net/images/server-platforms/discord/nodejs-2.png","alt":"","width":"90px"}}),_c('h4',{staticStyle:{"margin-top":"20px"}},[_vm._v(" NodeJS ")])]),_c('div',{staticClass:"product-features"},[_c('ul')])])]),_c('div',{staticClass:"column-33"},[_c('div',{staticClass:"product-box"},[_c('div',{staticClass:"product-header"},[_c('img',{staticStyle:{"margin-top":"calc((64px - 23.73px) / 2)","margin-bottom":"calc((64px - 23.73px) / 2)"},attrs:{"src":"https://cdn.galactiq.net/images/server-platforms/discord/python.png","alt":"","width":"100px"}}),_c('h4',{staticStyle:{"margin-top":"20px"}},[_vm._v(" Python ")])]),_c('div',{staticClass:"product-features"},[_c('ul')])])]),_c('div',{staticClass:"column-33"},[_c('div',{staticClass:"product-box"},[_c('div',{staticClass:"product-header"},[_c('img',{staticStyle:{"margin-top":"calc((64px - 23.73px) / 2)","margin-bottom":"calc((64px - 23.73px) / 2)"},attrs:{"src":"https://cdn.galactiq.net/images/server-platforms/discord/deno.png","alt":"","width":"100px"}}),_c('h4',{staticStyle:{"margin-top":"20px"}},[_vm._v(" Deno ")])]),_c('div',{staticClass:"product-features"},[_c('ul')])])]),_c('div',{staticClass:"column-33"},[_c('div',{staticClass:"product-box"},[_c('div',{staticClass:"product-header"},[_c('img',{staticStyle:{"margin-top":"calc((64px - 23.73px) / 2)","margin-bottom":"calc((64px - 23.73px) / 2)"},attrs:{"src":"https://cdn.galactiq.net/images/server-platforms/discord/dart.png","alt":"","width":"100px"}}),_c('h4',{staticStyle:{"margin-top":"20px"}},[_vm._v(" Dart ")])]),_c('div',{staticClass:"product-features"},[_c('ul')])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"content-row content-row-gray"},[_c('div',{staticClass:"container"},[_c('div',[_c('table',[_c('caption',[_vm._v(" Discord Bot Addons ")]),_c('tbody',[_c('tr',[_c('td',[_vm._v("Additional port (max 5)")]),_c('td',[_vm._v("$0.30 "),_c('small',[_vm._v("/ month")])])]),_c('tr',[_c('td',[_vm._v("1GB Extra storage (max 100)")]),_c('td',[_vm._v("$0.20 "),_c('small',[_vm._v("/ month")])])])])])])])])
}]

export { render, staticRenderFns }